class PriceList {

    static init() {
        const options = {
            valueNames: [ {data: ['name', 'num', 'free', 'size', 'floor', 'price', 'finance', 'type', 'dis', 'balcony', 'terrace', 'frontGarden'] } ]
        };
        const priceList = new List('priceList', options);

        const allFilterEl = document.querySelectorAll('.m-filter-checkboxes__group input, .m-filter-select__the');

        if (allFilterEl) {
            for (let i = 0; i < allFilterEl.length; ++i) {
                allFilterEl[i].addEventListener('change', function() {
                    filter();
                });
            }
        }

        // get min and max values for ranges
        const sizesArray = [],
            pricesArray = [],
            allFlats = document.getElementsByClassName('m-price-list__body__item');

        if (allFlats) {
            for (let i = 0; i < allFlats.length; ++i) {
                let size = parseInt( allFlats[i].getAttribute('data-size') ),
                    price = parseInt(allFlats[i].getAttribute('data-price') );

                sizesArray.push(size);
                pricesArray.push(price);
            }
        }

        Array.prototype.max = function() {
            return Math.max.apply(null, this);
        };
        Array.prototype.min = function() {
            return Math.min.apply(null, this);
        };

        function formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
        }

        let sizeArrayMin = sizesArray.min(),
            sizeArrayMax = sizesArray.max(),
            priceArrayMin = pricesArray.min(),
            priceArrayMax = pricesArray.max();

        let sizeMinEl = document.getElementById('rangeSizeMin'),
            sizeMaxEl = document.getElementById('rangeSizeMax'),
            priceMinEl = document.getElementById('rangePriceMin'),
            priceMaxEl = document.getElementById('rangePriceMax');

        if (sizeMinEl && sizeArrayMin) {
            sizeMinEl.innerText = formatNumber(sizeArrayMin);
        }

        if (sizeMaxEl && sizeArrayMax) {
            sizeMaxEl.innerText = formatNumber(sizeArrayMax);
        }

        if (priceMinEl && priceArrayMin) {
            priceMinEl.innerText = formatNumber(priceArrayMin);
        }

        if (priceMaxEl && priceArrayMax) {
            priceMaxEl.innerText = formatNumber(priceArrayMax);
        }

        const rangeSize = document.getElementById('rangeSize'),
            rangePrice = document.getElementById('rangePrice');

        const optionsSize = {
            start: [sizeArrayMin, sizeArrayMax],
            step: 10,
            connect: true,
            margin: 10,
            tooltips: true,
            range: {
                'min': [sizeArrayMin],
                'max': [sizeArrayMax]
            },
            ariaFormat: wNumb({
                decimals: 0
            }),
            format: wNumb({
                decimals: 0
            })
        };

        const optionsPrice = {
            start: [priceArrayMin, priceArrayMax],
            step: 10,
            connect: true,
            margin: 10,
            tooltips: true,
            range: {
                'min': [priceArrayMin],
                'max': [priceArrayMax]
            },
            ariaFormat: wNumb({
                decimals: 0
            }),
            format: wNumb({
                decimals: 0
            })
        };

        if (rangeSize) {
            noUiSlider.create(rangeSize, optionsSize);
        }
        if (rangePrice) {
            noUiSlider.create(rangePrice, optionsPrice);
        }

        if (rangeSize) {
            rangeSize.noUiSlider.on('change', function () {
                filter();
            });
        }

        if (rangePrice) {
            rangePrice.noUiSlider.on('change', function () {
                filter();
            });
        }

        function filter() {

            // remove all filters
            priceList.filter();

            const selectedType = document.querySelector('.m-filter-checkboxes input[name="type"]:checked'),
                selectedDis = document.querySelector('.m-filter-checkboxes input[name="dis"]:checked'),
                select = document.querySelector('.m-filter-select__the'),
                free = document.querySelector('.m-filter-checkboxes input[type="checkbox"]');


            // todo: change when the field will be visible
            /*
            let type = selectedType.getAttribute('data-value'),
                dis = selectedDis.getAttribute('data-value'),
                 sel = select.value,
                */
            let type = 'all',
                dis = 'all',
                sel = 'all',
                sizeMin = rangeSize.noUiSlider.get()[0],
                sizeMax = rangeSize.noUiSlider.get()[1],
                priceMin = rangePrice.noUiSlider.get()[0],
                priceMax = rangePrice.noUiSlider.get()[1];

            priceList.filter(function(item) {

                if (
                    (item.values().type === type || type === 'all') &&
                    (item.values().dis === dis || dis === 'all') &&
                    (item.values().name === sel || sel === 'all') &&
                    (item.values().price >= priceMin && parseInt(item.values().price) <= parseInt(priceMax)) &&
                    (parseInt(item.values().size) > parseInt(sizeMin) && parseInt(item.values().size) < parseInt(sizeMax))
                ) {
                    // if availability is checked
                    if (free.checked) {
                        if (item.values().free === '1') {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            });


        }
    }

    static toggleFilterContainer() {
        const btn = document.getElementById('toggleFilter'),
            filter = document.getElementById('filterContainer');

        if (btn && filter) {
            btn.addEventListener('click', function(e) {
                e.preventDefault();
                filter.classList.toggle('open');
                btn.classList.toggle('open');
            });
        }
    }
}

document.addEventListener("DOMContentLoaded", function() {
   PriceList.init();
   PriceList.toggleFilterContainer();
});
